import styled from "@emotion/styled";

export const SectionTitle = styled.h2`
  color: #50f4ff;
`;

export const ViewDiv = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
`;

export const IconList = styled.ul`
  list-style: none;
  margin-top: 1.5rem;
  padding-left: 0;
`

export const IconListItem = styled.li`
  display: inline;
  margin: 0.5rem;
`;

export const IconImg = styled.img`
  width: min(30px, 6vw);
  height: min(30px, 6vw);
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;
