import TwitterImg from "../assets/images/Twitter.svg";
import DiscordImg from "../assets/images/Discord.svg";
import TelegramImg from "../assets/images/Telegram.svg";
import YoutubeImg from "../assets/images/Youtube.svg";
import MediumImg from "../assets/images/Medium.svg";

export const SOCIALS = [
  {
    name: "Twitter",
    img: TwitterImg,
  },
  {
    name: "Discord",
    img: DiscordImg,
  },
  {
    name: "Telegram",
    img: TelegramImg,
  },
  {
    name: "Youtube",
    img: YoutubeImg,
  },
  {
    name: "Medium",
    img: MediumImg,
  },
];

export const PRODUCT_LIST = [
  {
    name: "BIFROST",
    url: "https://thebifrost.io/?utm_campaign=footer&utm_source=biport_page&utm_medium=web",
  },
  {
    name: "BiFi",
    url: "https://bifi.finance/?utm_campaign=footer&utm_source=biport_page&utm_medium=web",
  },
  {
    name: "BiFi X",
    url: "https://x.bifi.finance/?utm_campaign=footer&utm_source=biport_page&utm_medium=web",
  },
  {
    name: "BFC Bot",
    url: "https://bfcbot.com/",
  },
  {
    name: "Biport",
    url: "https://biport.io/",
  },
  {
    name: "ChainRunner Q",
    url: "https://q.chainrunner.io/home",
  }
]
