import React, { useEffect } from "react";
import styled from "@emotion/styled";
import Panel from "../../assets/images/BenefitPanel.png";
import { SectionTitle, ViewDiv } from "../Customs/SimpleComponents";
import { useInView } from "react-intersection-observer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ScrollMutex, UserViewState } from "../../store/coils";
import { pageScroll } from "../Utils/ScrollHandler";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation } from "swiper";

import "swiper/css/navigation";
import { useTranslation } from "react-i18next";

const Container = styled.section`
  background-color: #00001d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100vh;
`;

const Paper = styled.div`
  background-image: url(${Panel});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 31.5vw;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-size: 2vw;
  font-weight: bold;
  color: #fff;
  margin: 0;
  padding: 0;
`;

const BenefitSection = () => {
  const [ref, inView] = useInView();
  const [scrollMutex, setScrollMutex] = useRecoilState(ScrollMutex);
  const setUserView = useSetRecoilState(UserViewState);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (inView) {
      setUserView("Benefit");
    }
    else {
    }
  }, [inView, setUserView]);

  return (
    <Container
      id="Benefit"
      onWheel={(e) => {
        pageScroll(e, "Bitizn", "FAQ", scrollMutex, setScrollMutex);
      }}
    >
      <SectionTitle>Benefit</SectionTitle>
      <Paper>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          loop={true}
          style={{
            width: "70vw",
            textAlign: "center",
            color: "#fff",
            marginBottom: "3vw",
          }}
        >
          {(t("BenefitList", { returnObjects: true }) as String[]).map(
            (item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 10vw",
                    }}
                  >
                    <Text>{item}</Text>
                  </div>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      </Paper>
      <ViewDiv ref={ref}></ViewDiv>
    </Container>
  );
};

export default BenefitSection;
