import React from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { UserViewState } from "../store/coils";
import SideLink from "./SideLink";

const Container = styled.nav`
  position: fixed;
  right: 3vw;
  top: calc(50% - 3em);
  display: flex;
  z-index: 4;
  justify-content: flex-end;
  color: #fff;
  text-align: right;
  opacity: 0.7;
  @media (max-width: 900px) {
    display: none;
  }
`;

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled.li`
  margin-bottom: 1em;
`;

const SideNav = () => {
  const userView = useRecoilValue(UserViewState);

  return userView !== "Intro" ? (
    <Container>
      <List>
        <ListItem>
          <SideLink target="Story" />
        </ListItem>
        <ListItem>
          <SideLink target="Bitizn" />
        </ListItem>
        <ListItem>
          <SideLink target="Benefit" />
        </ListItem>
        <ListItem>
          <SideLink target="FAQ" />
        </ListItem>
      </List>
    </Container>
  ) : (
    <></>
  );
};

export default SideNav;
