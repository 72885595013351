import React, { useEffect, useMemo } from "react";
import logo from "./logo.svg";
import styled from "@emotion/styled";
import "./App.css";
import NavBar from "./components/NavBar";
import IntroSection from "./components/IntroSection";
import StorySection from "./components/Sections/StroySection";
import BitiznSection from "./components/Sections/BitiznSection";
import BenefitSection from "./components/Sections/BenefitSection";
import FAQSection from "./components/Sections/FAQSection";
import SideNav from "./components/SideNav";

import "./assets/fonts/fonts.css";
import Footer from "./components/Footer";
import { Events } from "react-scroll";
import { useSetRecoilState } from "recoil";
import { ScrollMutex } from "./store/coils";
import { useTranslation } from "react-i18next";

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function App() {
  const mutexLocker = useSetRecoilState(ScrollMutex);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {
      mutexLocker(true);
    });
    Events.scrollEvent.register("end", () => {
      mutexLocker(false);
    });
  }, [mutexLocker]);

  return (
    <div className={i18n.language}>
      <header>
        <NavBar></NavBar>
      </header>
        <IntroSection></IntroSection>
      <Main id="main">
        <SideNav></SideNav>
        <StorySection></StorySection>
        <BitiznSection></BitiznSection>
        <BenefitSection></BenefitSection>
      </Main>
      <div id="scrollable">
        <FAQSection></FAQSection>
        <footer>
          <Footer></Footer>
        </footer>
      </div>
    </div>
  );
}

export default App;
