import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Add } from "@mui/icons-material";
import { AccordionState } from "../../store/coils";
import { useRecoilState } from "recoil";

const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.14);
  width: 80vw;
  border: 1px solid #2ab2e6;
  box-sizing: border-box;
  border-radius: 10px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    transition: 0.3s;
    backdrop-filter: brightness(125%);
  }
  &:active {
    transition: 0.3s;
    backdrop-filter: brightness(150%);
  }
`;

const AccordionTitle = styled.h3`
  margin: 0;
  padding: 22px;
`;

const AccordionBody = styled.div<{ active: boolean; bodyHeight: number }>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: height 0.3s;

  ${({ active, bodyHeight }) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`;

const AccordionContent = styled.p`
  margin: 0;
  padding: 22px;
  height: auto;
`;

const Accordion = ({ title, content, Index }: { title: string; content: string, Index: number }) => {
  const _ref = useRef<HTMLParagraphElement>(null);
  const [active, setActive] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(0);
  const [currentIndex, setCurrentIndex] = useRecoilState(AccordionState);

  const Icon = useCallback(
    styled(Add)`
      margin-right: 24px;
      margin-left: 24px;
      ${active ? "color: #2ab2e6;transform: rotate(45deg);" : ""}
    `,
    [active, bodyHeight]
  );

  useEffect(() => {
    if(currentIndex===Index){
      setActive(true);
      if (_ref.current) {
        setBodyHeight(_ref.current.clientHeight);
      }
    }
    else {
      setActive(false);
    }
  }, [currentIndex, Index]);

  return (
    <AccordionContainer
      onClick={() => {
        if(currentIndex===Index){
          setCurrentIndex(-1);
        }
        else {
          setCurrentIndex(Index);
        }
      }}
    >
      <TitleContainer>
        <Icon></Icon>
        <AccordionTitle>{title}</AccordionTitle>
      </TitleContainer>
      <AccordionBody active={active} bodyHeight={bodyHeight}>
        <AccordionContent ref={_ref}>{content}</AccordionContent>
      </AccordionBody>
    </AccordionContainer>
  );
};

export default Accordion;
