import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";

import StoryBackground from "../../assets/images/StoryBackground.png";
import StoryBoard from "../../assets/images/StoryBoard.png";
import { useInView } from "react-intersection-observer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ScrollMutex, UserViewState } from "../../store/coils";
import { ViewDiv } from "../Customs/SimpleComponents";
import { pageScroll } from "../Utils/ScrollHandler";

import { useTranslation, withTranslation } from "react-i18next";

const Container = styled.section`
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100vh;
`;

const Canvas = styled.div`
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(${StoryBackground});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Paper = styled.div`
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${StoryBoard});
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10vh calc(min(10vw, 80px));
  text-align: center;
  height: min(calc(90vh - 10vh), calc(90vh - 10vh));
  width: 65vw;
  font-size: 1.5vw;
`;

const StoryTitle = styled.h2`
  font-size: 2.5vw;
  font-weight: 700;
`;

const ScrollContent = styled.div`
  position: relative;
  height: 20vw;
  width: calc(90% - min(10vw, 80px));
  scroll-behavior: smooth;
  overflow-y: scroll;
  z-index: 100;
  font-size: 1.2vw;
`;

const StorySection = () => {
  const [ref, inView] = useInView();
  const [contentRef, contentInView] = useInView();
  const setUserView = useSetRecoilState(UserViewState);
  const [scrollMutex, setScrollMutex] = useRecoilState(ScrollMutex);
  const [isMoreContent, setIsMoreContent] = useState(true);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (inView) {
      setUserView("Story");
    }
    else {
    }
  }, [inView, setUserView]);

  useEffect(()=>{
    if(contentInView){
      setIsMoreContent(false);
    }
    else{
      setIsMoreContent(true);
    }
  },[contentInView,setIsMoreContent]);

  return (
    <Container
      id="Story"
      onWheel={(e) => {
        pageScroll(e, "Intro", "Bitizn", scrollMutex, setScrollMutex);
      }}
    >
      <Canvas>
        <Paper>
          <StoryTitle className="storytitle">{t('StoryTitle')}</StoryTitle>
          <ScrollContent
            onWheel={(e) => {
              e.stopPropagation();
            }}
          >
            {(t("StoryContent", {returnObjects: true}) as String[]).map((content, index) => {
              return (
                (content==="") ? <br key={index}/> : <p key={index}>{content}</p>
              );
            })}
            <div ref={contentRef}></div>
          </ScrollContent>
          {isMoreContent ? <p>. . .</p> : <p></p>}
        </Paper>
      </Canvas>
      <ViewDiv ref={ref}></ViewDiv>
    </Container>
  );
};

export default withTranslation()(StorySection);
