import { useEffect, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";

import MainBackground from "../assets/images/MainBack.gif";
import { useInView } from "react-intersection-observer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ScrollMutex, UserViewState } from "../store/coils";
import { ViewDiv } from "./Customs/SimpleComponents";
import { pageScroll } from "./Utils/ScrollHandler";

import { MusicNote, MusicOff } from "@mui/icons-material";
import LocaleSelector from "./LocaleSelector";
import { useTranslation } from "react-i18next";
const BGMusic = require("../assets/audio/BGM.mp3");

const Container = styled.section`
  display: flex;
  position: relative;
  height: 100vh;
  background-image: url(${MainBackground});
  background-size: cover;
`;

const BgmButton = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 1rem;
  width: 35px;
  height: 35px;
  cursor: pointer;
  color: #fff;
  &:hover {
    opacity: 0.7;
  }
`;

const IntroSection = () => {
  const [ref, inView] = useInView();
  const [isPlaying, setIsPlaying] = useState(false);
  const conRef = useRef(null);
  const setUserView = useSetRecoilState(UserViewState);
  const [scrollMutex, setScrollMutex] = useRecoilState(ScrollMutex);

  useEffect(() => {
    if (inView) {
      setUserView("Intro");
    }
  }, [inView, setUserView]);

  const bgm = useMemo(() => {
    const audio = new Audio(BGMusic);
    audio.loop = true;
    return audio;
  }, []);

  return (
    <Container
      id="Intro"
      onWheel={(e) => {
        pageScroll(e, "Intro", "Story", scrollMutex, setScrollMutex);
      }}
      ref={conRef}
    >
      <ViewDiv ref={ref}></ViewDiv>
      <LocaleSelector></LocaleSelector>
      <BgmButton
        onClick={() => {
          if(bgm.paused) {
            bgm.play();
          }
          else {
            bgm.pause();
          }
          setIsPlaying(!isPlaying);
        }}
      >
        {isPlaying ? (
          <MusicNote color="inherit" sx={{ width: "100%", height: "100%" }} />
        ) : (
          <MusicOff color="inherit" sx={{ width: "100%", height: "100%" }} />
        )}
      </BgmButton>
    </Container>
  );
};

export default IntroSection;
