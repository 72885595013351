import { scroller } from "react-scroll";
import { SetterOrUpdater } from "recoil";

export const pageScroll = (event: any, prev: string, next: string, mutex: boolean, mutexLocker: SetterOrUpdater<boolean>) => {
  if (mutex) {
    return;
  }
  if (Math.abs(event.deltaY) > 1) {
    if (event.deltaY > 0) {
      if(!next){
        mutexLocker(false);
        return;
      }
      scroller.scrollTo(next, {
        duration: 700,
        delay: 0,
        smooth: 'ease-out',
        ignoreCancelEvents: true,
      });
    } else {
      if(!prev){
        mutexLocker(false);
        return;
      }
      scroller.scrollTo(prev, {
        duration: 700,
        delay: 0,
        smooth: 'ease-out',
        ignoreCancelEvents: true,
      });
    }
  }
};
