import { atom } from "recoil";

export const UserViewState = atom({
  key: "UserViewState",
  default: ''
});

export const ScrollMutex = atom({
  key: "ScrollMutex",
  default: false
});

export const AccordionState = atom({
  key: "AccordionState",
  default: -1
});
