import React from "react";
import styled from "@emotion/styled";
import { SOCIALS } from "../store/constants";
import { IconList, IconListItem, IconImg } from "./Customs/SimpleComponents";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #fff;
  padding: 0 1rem;
  width: calc(100% - 2rem);
  z-index: 5;
`;

const Logo = styled.h2`
  padding-left: 4vw;
  font-size: 2rem;
`;

const NavBar = () => {
  const [t, i18n] = useTranslation();

  return (
    <Container>
      <Logo>Bitizn</Logo>
      <IconList>
        {SOCIALS.map((social, index) => (
          <IconListItem key={index}>
            <a
              href={t("CommunityURL." + social.name)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconImg src={social.img} alt={social.name} />
            </a>
          </IconListItem>
        ))}
      </IconList>
    </Container>
  );
};

export default NavBar;
