import React, { useState } from "react";
import styled from "@emotion/styled/macro";

import UsIcon from "../assets/images/en.svg";
import KrIcon from "../assets/images/ko.svg";
import ChIcon from "../assets/images/ch.svg";
import { useTranslation } from "react-i18next";

const LangList = ["en", "en-us", "ko", "ko-kr", "zh", "zh-cn"];
const LangIcon = {
  en: UsIcon,
  "en-us": UsIcon,
  ko: KrIcon,
  "ko-kr": KrIcon,
  zh: ChIcon,
  "zh-cn": ChIcon,
};

type ToggleProp = {
  isOpen: boolean;
};

const LocaleIcon = styled.img<ToggleProp>`
  width: 35px;
  height: 35px;
  cursor: pointer;
  opacity: 0.5;
  display: none;
  margin: 5px 0;
  &:hover {
    opacity: 0.7;
  }
  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

const Container = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 1rem;
  width: 35px;
  height: auto;
  cursor: pointer;
`;

const LocaleSelector = () => {
  const [t, i18n] = useTranslation();
  const [toggleOn, setToggleOn] = useState(false);

  const curLangIndex = LangList.indexOf(i18n.language);

  return (
    <Container>
      {LangList.map(
        (lang, index) =>
          !((index - curLangIndex) % 2) &&
          lang !== i18n.language && (
            <LocaleIcon
              src={(LangIcon as any)[lang]}
              alt={lang}
              key={index}
              onClick={() => {
                i18n.changeLanguage(lang);
                setToggleOn(false);
              }}
              isOpen={toggleOn}
            />
          )
      )}{" "}
      <LocaleIcon
        src={(LangIcon as any)[i18n.language]}
        alt={i18n.language}
        style={{
          display: "block",
        }}
        isOpen={toggleOn}
        onClick={() => setToggleOn(!toggleOn)}
      />
    </Container>
  );
};

export default LocaleSelector;
