import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import StringEn from "./en.json";
import StringKo from "./ko.json";
import StringCh from "./ch.json";

const resources = {
  en_us: {
    translation: StringEn
  },
  ko_kr: {
    translation: StringKo
  },
  zh_cn: {
    translation: StringCh
  },
  en: {
    translation: StringEn
  },
  ko: {
    translation: StringKo
  },
  zh: {
    translation: StringCh
  }

};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      lookupSessionStorage: 'i18nextLng',
      // cache user language
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'],
    },
    resources,
    lowerCaseLng: true,
    load: 'languageOnly',
    supportedLngs: ['en','en-us', 'ko', 'ko-kr', 'zh', 'zh-cn'],
    fallbackLng: 'ko',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
