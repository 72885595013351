import React, { useState, useCallback } from "react";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import { UserViewState } from "../store/coils";

const LineContainer = styled.div`
  width: 1.5em;
  height: 1.5em;
  display: flex;
  justify-content: flex-end;
`;

const Line = styled.div`
  width: 1.5em;
  height: 1px;
  background-color: #fff;
  transform: rotate(45deg);
`;

const Text = styled.span`
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  margin-top: -0.5em;
`;

const SideLink = ({ target }: { target: string }) => {
  const userView = useRecoilValue(UserViewState);
  const [mousePos, setMousePos] = useState("");

  return userView === target || mousePos === target ? (
    <LineContainer>
      <Text>{target}</Text>
    </LineContainer>
  ) : (
    <LineContainer>
      <Line></Line>
    </LineContainer>
  );
};

export default SideLink;
