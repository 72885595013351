import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { SectionTitle, ViewDiv } from "../Customs/SimpleComponents";
import Accordion from "../Customs/Accordion";
import { useInView } from "react-intersection-observer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ScrollMutex, UserViewState } from "../../store/coils";
import { pageScroll } from "../Utils/ScrollHandler";
import { useTranslation } from "react-i18next";

const Container = styled.section`
  display: flex;
  background-color: #00001d;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  margin-top: -10px;
  padding-bottom: 0.83em;
  position: relative;
  min-height: 100vh;
`;

const FAQSection = () => {
  const [ref, inView] = useInView();
  const setUserView = useSetRecoilState(UserViewState);
  const [scrollMutex, setScrollMutex] = useRecoilState(ScrollMutex);
  const [t, i18n] = useTranslation();

  useEffect(() => {
    if (inView) {
      setUserView("FAQ");
    } else {
    }
  }, [inView, setUserView]);

  return (
    <Container
      id="FAQ"
      onWheel={(e) => {
        if (inView) {
          pageScroll(e, "Benefit", "", scrollMutex, setScrollMutex);
        }
      }}
    >
      <ViewDiv ref={ref}></ViewDiv>
      <SectionTitle>FAQ</SectionTitle>
      {(
        t("FAQ", { returnObjects: true }) as Array<{
          Question: string;
          Answer: string;
        }>
      ).map((item, index) => {
        return (
          <Accordion
            title={item.Question}
            content={item.Answer}
            key={index}
            Index={index}
          />
        );
      })}
    </Container>
  );
};

export default FAQSection;
