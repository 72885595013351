import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

import NFT1 from "../../assets/images/bitizns/NFT1.png";
import NFT2 from "../../assets/images/bitizns/NFT2.png";
import NFT3 from "../../assets/images/bitizns/NFT3.png";
import NFT4 from "../../assets/images/bitizns/NFT4.png";
import NFT5 from "../../assets/images/bitizns/NFT5.png";
import NFT6 from "../../assets/images/bitizns/NFT6.png";
import NFT7 from "../../assets/images/bitizns/NFT7.png";
import NFT8 from "../../assets/images/bitizns/NFT8.png";
import NFT9 from "../../assets/images/bitizns/NFT9.png";
import NFT10 from "../../assets/images/bitizns/NFT10.png";
import NFT11 from "../../assets/images/bitizns/NFT11.png";
import NFT12 from "../../assets/images/bitizns/NFT12.png";
import NFT13 from "../../assets/images/bitizns/NFT13.png";

import { useInView } from "react-intersection-observer";
import { useRecoilState, useSetRecoilState } from "recoil";
import { ScrollMutex, UserViewState } from "../../store/coils";
import { ViewDiv } from "../Customs/SimpleComponents";
import { pageScroll } from "../Utils/ScrollHandler";

import AirDropImg from "../../assets/images/AirdropBtn.png";
import { ArrowCircleRightOutlined } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const Container = styled.section`
  position: relative;
  height: 100vh;
  background-color: black;
`;

const Paper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
`;

const Image = styled.img`
  width: 300px;
  height: auto;
`;

const AirdropButton = styled.div`
  position: absolute;
  bottom: 10vh;
  right: 5vw;
  background-image: url(${AirDropImg});
  background-size: contain;
  background-repeat: no-repeat;
  width: 44.5833333vh;
  height: 10vh;
  color: #50f4ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  font-weight: bold;
  max-width: 222px;
  max-height: 50px;
  font-family: "Montserrat", sans-serif;
`;

const BitiznSection = () => {
  const [ref, inView] = useInView();
  const [scrollMutex, setScrollMutex] = useRecoilState(ScrollMutex);
  const setUserView = useSetRecoilState(UserViewState);
  const [t, i18n] = useTranslation();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const setWidth = useCallback(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", setWidth);
    return () => window.removeEventListener("resize", setWidth);
  }, [setWidth]);

  useEffect(() => {
    if (inView) {
      setUserView("Bitizn");
    }
    else {
    }
  }, [inView, setUserView]);

  return (
    <Container
      id="Bitizn"
      onWheel={(e) => {
        pageScroll(e, "Story", "Benefit", scrollMutex, setScrollMutex);
      }}
    >
      <Paper>
        <Swiper
          modules={[Autoplay]}
          loop={true}
          speed={5000}
          autoplay={{
            delay: 1,
            disableOnInteraction: true,
          }}
          simulateTouch={false}
          spaceBetween={20}
          slidesPerView={windowWidth / 320}
        >
          <SwiperSlide>
            <Image src={NFT1} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT2} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT3} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT4} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT5} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT6} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT7} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT8} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT9} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT10} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT11} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT12} />
          </SwiperSlide>
          <SwiperSlide>
            <Image src={NFT13} />
          </SwiperSlide>
        </Swiper>
      </Paper>
      <a href={t("AirdropURL")} target="_blank" rel="noopener noreferrer">
        <AirdropButton>
          Bitizn Airdrop
          <ArrowCircleRightOutlined sx={{ marginLeft: "0.5em" }} />
        </AirdropButton>
      </a>
      <ViewDiv ref={ref}></ViewDiv>
    </Container>
  );
};

export default BitiznSection;
