import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { PRODUCT_LIST, SOCIALS } from "../store/constants";
import {
  IconImg,
  IconList,
  IconListItem,
  ViewDiv,
} from "./Customs/SimpleComponents";

import FootLogo from "../assets/images/FootLogo.svg";
import { useInView } from "react-intersection-observer";
import { enableBodyScroll, disableBodyScroll } from "body-scroll-lock";
import { useSetRecoilState } from "recoil";
import { UserViewState } from "../store/coils";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #000;
  color: #fff;
  justify-content: space-between;
  padding: 10px 7vw;
  padding-top: 15px;
  font-family: "Montserrat", sans-serif;
  width: 86vw;
`;

const Paper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ColPaper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Logo = styled.img`
  width: min(120px, 30vw);
`;

const Title = styled.h5`
  font-weight: bold;
  font-size: min(1rem, 1.5vw);
  margin: 0;
`;

const LinkList = styled.ul`
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0;
`;

const LinkItem = styled.li`
  font-size: min(1rem, 1.5vw);
`;

const TitleLayer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  font-size: min(1rem, 1.5vw);
  margin: 0;
  opacity: 0.7;
`;

const BabyText = styled.p`
  font-size: min(1rem, 1.5vw);
  opacity: 0.7;
  margin: 0.5rem 0;
  &:hover {
    opacity: 1;
  }
`;

const FootIconList = () => {
  const [t, i18n] = useTranslation();

  return (
    <IconList>
      {SOCIALS.map((social, index) => (
        <IconListItem key={index}>
          <a
            href={t("CommunityURL." + social.name)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconImg src={social.img} alt={social.name} />
          </a>
        </IconListItem>
      ))}
    </IconList>
  );
};

const ProductList = () => {
  return (
    <LinkList>
      {PRODUCT_LIST.map(({ name, url }) => (
        <LinkItem key={name}>
          <a
            href={url}
            style={{
              color: "#fff",
              textDecoration: "none",
            }}
          >
            <BabyText>{name}</BabyText>
          </a>
        </LinkItem>
      ))}
    </LinkList>
  );
};

const Footer = () => {
  const [ref, inView] = useInView();
  const setUserView = useSetRecoilState(UserViewState);
  const [t, i18n] = useTranslation();

  return (
    <ColPaper
      style={{
        gap: "0px",
      }}
    >
      <Container>
        <ColPaper
          style={{
            justifyContent: "space-between",
          }}
        >
          <Paper>
            <Logo src={FootLogo} alt="footer-logo" />
            <ColPaper
              style={{
                marginLeft: "1rem",
              }}
            >
              <Title>Powered by BIFROST</Title>
              <BabyText>2022 bitizn.io</BabyText>
              <TitleLayer>
                <Description>{t("FooterTitle")}</Description>
                <Description>{t("FooterDesc")}</Description>
              </TitleLayer>
            </ColPaper>
          </Paper>
          <Paper>
            <FootIconList />
          </Paper>
        </ColPaper>
        <ColPaper>
          <Title>PRODUCT</Title>
          <ProductList></ProductList>
        </ColPaper>
      </Container>
      <Paper
        style={{
          backgroundColor: "#161e31",
          justifyContent: "center",
          color: "#fff",
        }}
      >
        <p
          style={{
            opacity: "0.7",
          }}
        >
          © 2022 BIFROST
        </p>
      </Paper>
      <ViewDiv ref={ref}></ViewDiv>
    </ColPaper>
  );
};

export default Footer;
